import { useEffect } from 'react'
import PasswordDetails from '../components/PasswordDetails'
import PasswordForm from '../components/PasswordForm'
import { usePasswordsContext } from '../hooks/usePasswordsContext'
import { useAuthContext } from '../hooks/useAuthContext'

const Home = () => {

    const {passwords, dispatch} = usePasswordsContext()
    const {user} = useAuthContext()

    useEffect(() =>{
        
        const fetchPasswords = async () => {
            const response = await fetch('/api/passwords', {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            })
            const json = await response.json()

            if(response.ok){
                dispatch({type: 'SET_PASSWORDS', payload: json})
            }
        }

        if(user){
            fetchPasswords()
        }

    }, [dispatch, user])

    return ( 
        <div className="home">
            <div className="passwords">
                {passwords && passwords.map((password) => (
                    <PasswordDetails key={password._id} password={password} />
                ))}
            </div>
            <PasswordForm />
        </div>
     );
}
 
export default Home;