import { AuthContext } from '../context/AuthContext'
import { useContext } from 'react'

export const useAuthContext = () => {
    // destructure the user from the context object
    const context = useContext(AuthContext)

    if(!context){
        throw Error('useAuthContext must be used within a AuthContextProvider')
    }

    return context
}