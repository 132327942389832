import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';

const Header = () => {

    const { logout } = useLogout()
    const { user } = useAuthContext()

    const handleClick = () => {
        logout()
    }

    return ( 
        <header>
            <div className="container">
                <Link to="/">
                    <div className="logo-container">
                        <h1>PW0RG</h1>
                        <img id='pw0rg' src="/pw0rg-logo.svg" alt="PW0RG Logo"/>
                    </div>
                </Link>
                <nav>
                    {user && (<div>
                        <span>{user.email}</span>
                        <button onClick={handleClick}>Log Out</button>
                    </div> )}
                    {!user && ( <div>
                        <Link to="/login">Login</Link>
                        <Link to="/signup">Signup</Link>
                    </div> )}
                </nav>
            </div>
        </header>
    );
}
 
export default Header;